import axios from 'axios';
import { ServerActions, ServerApiPath, ServerDateFormat, UseMocks } from '../utils/consts';
import { useMutation, UseMutationResult } from 'react-query';
import { MultiLangProjectItem, MultiLangProjectsRaw, ProjectItemRaw } from '../utils/interfaces';
import moment, { Locale } from 'moment';
import { getToken } from '../utils/helpers';

const fetch = async (data: MultiLangProjectItem): Promise<void> => {
  if (UseMocks) {
    return;
  }

  const rawData = {} as MultiLangProjectsRaw;

  for (const lang in data) {
    // @ts-ignore
    const item = data[lang as Locale];
    const dateStart = moment(item.dateStart);
    const datePublish = moment(item.datePublish);

    // @ts-ignore
    rawData[lang as Locale] = {
      id: item.id,
      title: item.title,
      images: item.images,
      mainImage: item.mainImage,
      description: item.description,
      dateStart: dateStart.format(ServerDateFormat),
      datePublish: datePublish.isValid() ? datePublish.format(ServerDateFormat) : undefined,
      links: item.links.map(({ title, path }: { title: string; path: string }) => ({ title, path })),
      video: item.video,
      shortDescription: item.shortDescription,
      page: item.page,
    } as ProjectItemRaw;
  }

  const token = getToken();
  await axios.post(`${ServerApiPath}?action=${ServerActions.saveProject}&token=${token}`, { data: rawData });
  return;
};

export const useSaveProject = (): UseMutationResult<void, unknown, MultiLangProjectItem, unknown> => {
  return useMutation((data: MultiLangProjectItem) => fetch(data));
};
