import { Routes, Route } from 'react-router-dom';
import { routes } from './routes';
import { Footer, Header } from './components';
import { LocaleProvider } from './hooks/useTranslate';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PopupProvider } from './hooks/usePopup';

const queryClient = new QueryClient();

const App = () => {
  return (
    <div className='App'>
      <QueryClientProvider client={queryClient}>
        <LocaleProvider>
          <PopupProvider>
            <Header />
            <Routes>
              {routes.map((item, index) => {
                const PageComponent = item.component;
                return <Route key={index} path={item.path} element={<PageComponent />} />;
              })}
            </Routes>
            <Footer />
          </PopupProvider>
        </LocaleProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
