import { PropsWithChildren } from 'react';

export type RowProps = PropsWithChildren<{
  reverse?: boolean;
  className?: string;
  gap?: string;
  style?: any;
  useSpan?: boolean;
}>;

export const Row = ({ children, useSpan = false, reverse = false, className = '', gap = '', style = {} }: RowProps) => {
  const classes = `row${reverse ? ' .reverse' : ''} ${className}`;
  const flexGap = gap ? { gap, ...style } : style;

  if (useSpan) {
    return (
      <span className={classes} style={flexGap}>
        {children}
      </span>
    );
  }

  return (
    <div className={classes} style={flexGap}>
      {children}
    </div>
  );
};
