import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { usePopup } from '../hooks/usePopup';
import { Row } from './Row';
import { Col } from './Col';

interface ModalWrapperProps extends PropsWithChildren {
  header?: ReactNode;
  onClose: () => void;
  className?: string;
  options?: {
    level?: number;
  };
}

interface ModalProps extends ModalWrapperProps {
  visible: boolean;
  id: string;
}

const DefaultOverflowZIndex = 200;
const DefaultContentZIndex = DefaultOverflowZIndex + 1;

const ModalWrapper = ({ children, header, onClose, className = '', options = { level: 1 } }: ModalWrapperProps) => {
  return (
    <>
      <div
        className='modal-overflow'
        style={{ zIndex: DefaultOverflowZIndex + (options?.level || 1) }}
        onClick={onClose}
      />
      <div className={`modal ${className}`} style={{ zIndex: DefaultContentZIndex + (options?.level || 1) }}>
        <button className='close' onClick={onClose}></button>
        {header && (
          <Row className='header'>
            <Col>
              <h3>{header}</h3>
            </Col>
          </Row>
        )}
        <Row className='content'>
          <Col>{children}</Col>
        </Row>
      </div>
    </>
  );
};

export const Modal = ({ id, children, visible, header, onClose, className, options }: ModalProps) => {
  const { showModal, hideModal } = usePopup();

  useEffect(() => {
    if (visible) {
      showModal(
        id,
        <ModalWrapper key={id} options={options} header={header} className={className} onClose={onClose}>
          {children}
        </ModalWrapper>,
      );
      return;
    }
    hideModal(id);
  }, [visible, id]);

  return null;
};
