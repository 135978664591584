import { useEffect, useRef, useState } from 'react';
import { Rem } from '../../utils/consts';
import { Col, Row } from '../../components';
import { ImageModal } from './imageModal';

const ImageLines = 2;

const Image = ({ imageSize, image }: { imageSize: number; image: string }) => {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <Col>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <img
        onClick={() => setModalVisible(true)}
        style={{ width: imageSize, height: imageSize }}
        src={image}
        alt={image}
      />
      <ImageModal image={image} modalVisible={modalVisible} hideModal={() => setModalVisible(false)} />
    </Col>
  );
};

export const ImagesBlock = ({ images }: { images: string[] }) => {
  const imageWrapperRef = useRef<HTMLDivElement | null>(null);

  const [imageSize, setImageSize] = useState<number>(0);

  useEffect(() => {
    if (imageWrapperRef?.current) {
      const rect = imageWrapperRef.current.getBoundingClientRect();
      setImageSize((rect.height - 4 * Rem) / ImageLines);
    }
  }, []);

  const newImagesList: string[][] = [];
  const itemsPerLine = Math.round(images.length / ImageLines);
  for (let i = 0; i < images.length; i++) {
    const index = Math.floor(i / itemsPerLine);
    if (!Array.isArray(newImagesList[index])) {
      newImagesList[index] = [];
    }
    newImagesList[index].push(images[i]);
  }

  if (newImagesList.length === 0) {
    return null;
  }

  return (
    <div className='images-wrapper col' ref={imageWrapperRef}>
      {newImagesList.map((imageListArray, index) => (
        <Row key={index}>
          {imageListArray.map((item) => (
            <Image image={item} imageSize={imageSize} key={item} />
          ))}
        </Row>
      ))}
    </div>
  );
};
