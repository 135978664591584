import { useTranslate } from '../../hooks/useTranslate'
import { NavLink } from 'react-router-dom'
import { Paths } from '../../utils/consts'

export const Page404 = () => {
  const { t } = useTranslate()

  return (
    <main className='page page-404'>
      <div className='background'>
        <div className='message'>
          <h1 className='text-glowing'>{t('pages.page404.title')}</h1>
          <p className='text-glowing'>{t('pages.page404.subtext')}</p>
          <NavLink to={Paths.index}>{t('pages.page404.button')}</NavLink>
        </div>
      </div>
    </main>
  )
}
