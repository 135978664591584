import { NewsItem } from '../../utils/interfaces';
import moment from 'moment';
import Markdown from 'react-markdown';
import { PublicDateFormat, PublicShortDateFormat } from '../../utils/consts';

export type DateFormat = typeof PublicDateFormat | typeof PublicShortDateFormat;

export interface ItemProps extends NewsItem {
  onClick?: () => void;
  active?: boolean;
  opened?: boolean;
  format?: DateFormat;
}

export const Item = ({
  title,
  text,
  date,
  active = false,
  format = PublicDateFormat,
  onClick,
  opened = false,
}: ItemProps) => {
  return (
    <div onClick={onClick} className={`news-item ${active ? 'active' : ''}`}>
      <h1 className='title'>
        {opened ? <>&gt; </> : ''}
        {title}
      </h1>
      <span className='date'>{moment(date).format(format)}</span>
      <div className='text'>
        <Markdown>{text}</Markdown>
      </div>
    </div>
  );
};
