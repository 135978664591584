import { useQuery, UseQueryResult } from 'react-query';
import { AdditionalConfig, ServerActions, ServerApiPath, ServerDateFormat, UseMocks } from '../utils/consts';
import axios from 'axios';
import ProjectMock from '../mocks/projects.json';
import { ProjectItem, ProjectItemRaw } from '../utils/interfaces';
import moment from 'moment';
import { DetectLocale } from '../hooks/useTranslate';

const fetch = async (): Promise<ProjectItemRaw[]> => {
  if (UseMocks) {
    return ProjectMock;
  }
  const lang = DetectLocale();
  const response = await axios.get(`${ServerApiPath}?action=${ServerActions.getProjects}&language=${lang}`);
  return response.data.results?.result;
};

export const serializer = (itemRaw: ProjectItemRaw): ProjectItem | null => {
  const dateStart = moment(itemRaw.dateStart, ServerDateFormat);
  const datePublish = moment(itemRaw.datePublish, ServerDateFormat);
  if (dateStart.isValid()) {
    return {
      id: itemRaw.id,
      groupId: itemRaw.groupId,
      title: itemRaw.title,
      images: itemRaw.images,
      mainImage: itemRaw.mainImage,
      description: itemRaw.description,
      dateStart: dateStart.toDate(),
      datePublish: datePublish.isValid() ? datePublish.toDate() : undefined,
      links: itemRaw.links.map(({ title, path }) => ({ title, path })),
      video: itemRaw.video,
      shortDescription: itemRaw.shortDescription,
      page: itemRaw.page,
    };
  }

  return null;
};

export const useGetProjects = (): UseQueryResult<ProjectItem[]> => {
  return useQuery(['projects'], () => fetch(), {
    select: (data) =>
      (data.map(serializer).filter((item) => item !== null) as ProjectItem[]).sort(
        (a, b) => b.dateStart.valueOf() - a.dateStart.valueOf(),
      ),
    ...AdditionalConfig,
  });
};
