import axios from 'axios';
import { ServerActions, ServerApiPath, UseMocks } from '../utils/consts';
import { useMutation, UseMutationResult } from 'react-query';
import { getToken } from '../utils/helpers';

const fetch = async (extension: string, data: string): Promise<string> => {
  if (UseMocks) {
    return 'path_here';
  }

  const token = getToken();
  const result = await axios.post(`${ServerApiPath}?action=${ServerActions.upload}&token=${token}`, {
    ext: extension,
    file: data,
  });

  if (!result.data.results?.status) {
    throw new Error('error uploading');
  }
  return result.data.results?.path;
};

export const useUpload = (): UseMutationResult<string, unknown, { extension: string; data: string }, unknown> => {
  return useMutation(({ extension, data }) => fetch(extension, data));
};
