import { Col, Container, Row } from './'

export const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <footer>
      <Container>
        <Row>
          <Col></Col>
          <Col className='copy'>2022 - {currentYear} &copy; Desir Savoir</Col>
        </Row>
      </Container>
    </footer>
  )
}
