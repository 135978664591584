import axios from 'axios';
import { ServerActions, ServerApiPath, UseMocks } from '../utils/consts';
import { useMutation, UseMutationResult } from 'react-query';

const fetch = async (email: string): Promise<void> => {
  if (UseMocks) {
    return;
  }

  await axios.post(`${ServerApiPath}?action=${ServerActions.subscribe}`, { email });
  return;
};

export const useSubscribe = (): UseMutationResult<void, unknown, string, unknown> => {
  return useMutation((email: string) => fetch(email));
};
