import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import { useEffect, useState } from 'react';
import ParticlesConfig from '../utils/particles.json';

export const ParticlesElement = ({ className = '' }: { className?: string }) => {
  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  if (!init) return null;

  return <Particles id='particles' className={className} options={ParticlesConfig as any} />;
};
