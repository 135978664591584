import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import En from '../i18n/en.json';
import Ru from '../i18n/ru.json';
import De from '../i18n/de.json';
import { DefaultLocale, LanguageAliases, NoTranslationMessage } from '../utils/consts';
import { LocaleContextProps, TranslateProp } from '../utils/interfaces';
import { useQueryClient } from 'react-query';

const LocaleData = {
  en: En,
  ru: Ru,
  de: De,
} as const;

export type Locale = keyof typeof LocaleData;

const LocaleContext = createContext<LocaleContextProps>({
  t: () => '',
  changeLocale: () => {},
  localeList: [],
  locale: DefaultLocale,
});

export const DetectLocale = () => {
  const storageLocale = localStorage.getItem('locale');

  if (storageLocale) {
    return storageLocale;
  }
  const languages = navigator.languages;

  if (languages?.length > 0) {
    let selected = DefaultLocale;
    const localeList = Object.keys(LocaleData);

    for (let i = 0; i < languages.length; i++) {
      let lang = languages[i];

      if (LanguageAliases[lang]) {
        lang = LanguageAliases[lang];
      }

      if (localeList.includes(lang)) {
        selected = lang;
        break;
      }
    }
    return selected;
  }

  return DefaultLocale;
};

export const LocaleProvider = ({ children }: PropsWithChildren) => {
  const [locale, setLocale] = useState<Locale>(DetectLocale() as Locale);
  const queryClient = useQueryClient();
  const changeLocale = useCallback(
    async (newLocale: Locale) => {
      if (Object.keys(LocaleData).includes(newLocale) && locale !== newLocale) {
        setLocale(newLocale);
        localStorage.setItem('locale', newLocale);
        await queryClient.invalidateQueries({ queryKey: ['news'] });
        await queryClient.invalidateQueries({ queryKey: ['projects'] });
      }
    },
    [locale],
  );

  const translate = (path: string, props?: TranslateProp) => {
    const translation = LocaleData[locale];
    const pathList = path.split('.');
    let activeElement: any = translation;

    for (let i = 0; i < pathList.length; i++) {
      activeElement = activeElement[pathList[i]];

      if (!activeElement) {
        return NoTranslationMessage;
      }
    }
    if (activeElement) {
      if (typeof activeElement === 'string' && Object.keys(props || {}).length) {
        for (let key in props) {
          activeElement = activeElement.replaceAll(`{${key}}`, props[key]);
        }
      }

      return activeElement;
    }
    return NoTranslationMessage;
  };

  const context = {
    t: translate,
    changeLocale,
    localeList: Object.keys(LocaleData) as Locale[],
    locale,
  };
  return <LocaleContext.Provider value={context}>{children}</LocaleContext.Provider>;
};

export const useTranslate = () => useContext(LocaleContext);
