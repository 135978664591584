import { Col, Input, Row } from '../../components';
import { useState } from 'react';
import { useTranslate } from '../../hooks/useTranslate';
import { useLogin } from '../../queries/useLogin';
import { StorageTokenKey } from '../../utils/consts';
import { md5 } from '../../utils/helpers';

interface LoginProps {
  onComplete: () => void;
}

export const Login = ({ onComplete }: LoginProps) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslate();
  const { mutateAsync } = useLogin();

  const onSubmit = async () => {
    if (login.trim() !== '' && password.trim() !== '') {
      setIsLoading(true);
      const result = await mutateAsync({
        login,
        password: md5(password),
      });
      if (result) {
        setIsLoading(false);
        window.localStorage.setItem(StorageTokenKey, result);
        onComplete();
      }
    }
  };

  return (
    <div className='login-wrapper'>
      <Row>
        <Col>
          <Input onChange={(value) => setLogin(value)} title={t('pages.admin.login.username')} type='text' />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input onChange={(value) => setPassword(value)} title={t('pages.admin.login.password')} type='password' />
        </Col>
      </Row>
      <Row>
        <Col>
          <button onClick={onSubmit} disabled={isLoading}>
            {t('pages.admin.login.enter')}
          </button>
        </Col>
      </Row>
    </div>
  );
};
