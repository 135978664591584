import { useNavigate } from 'react-router-dom';
import { Paths } from '../utils/consts';

export const Logo = ({ withWrap = false, className = '' }: { withWrap?: boolean; className?: string }) => {
  const navigate = useNavigate();
  return (
    <span onClick={() => navigate(Paths.index)} className={`logo ${withWrap ? 'wrap' : ''} ${className}`}>
      {withWrap ? (
        <>
          <span>Desir</span>
          <span className='second-word'>
            <span className='first-letter'>S</span>avoir
          </span>
        </>
      ) : (
        'Desir Savoir'
      )}
    </span>
  );
};
