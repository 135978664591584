import { Col, Container, Row } from '../../components';
import { Input } from '../../components/';
import { useTranslate } from '../../hooks/useTranslate';
import { useState } from 'react';
import { useContactUs } from '../../queries/useContactUs';

export const Contacts = () => {
  const { t } = useTranslate();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const { mutateAsync } = useContactUs();

  const onSend = async () => {
    await mutateAsync({ name, email, text: message });
    setMessage('');
    setName('');
    setEmail('');
    alert('👍');
  };

  return (
    <main className='page contacts'>
      <Container className='form-container'>
        <form>
          <Row>
            <Col>
              <h1>{t('pages.contacts.title')}</h1>
            </Col>
          </Row>
          <Row>
            <Col className='form-item-wrapper'>
              <Input
                onChange={(value) => setEmail(value)}
                value={email}
                type='email'
                title={t('pages.contacts.form.email')}
              />
            </Col>
          </Row>
          <Row>
            <Col className='form-item-wrapper'>
              <Input
                onChange={(value) => setName(value)}
                value={name}
                type='name'
                title={t('pages.contacts.form.name')}
              />
            </Col>
          </Row>
          <Row>
            <Col className='form-item-wrapper'>
              <Input
                onChange={(value, e) => {
                  e.target.style.height = 'inherit';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                  setMessage(value);
                }}
                value={message}
                type='textarea'
                title={t('pages.contacts.form.message')}
              />
            </Col>
          </Row>
          <Row>
            <Col className='form-item-wrapper data-processing'>
              <h4>{t('pages.contacts.subtext.title')}</h4>
              <p>{t('pages.contacts.subtext.content')}</p>
            </Col>
          </Row>
          <Row className='button-wrapper'>
            <button onClick={onSend} type='submit'>
              {t('pages.contacts.form.button')}
            </button>
          </Row>
        </form>
      </Container>
    </main>
  );
};
