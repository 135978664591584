import { Paths } from './utils/consts';
import { Index } from './pages/Index';
import { News } from './pages/News';
import { AboutUs } from './pages/AboutUs';
import { Contacts } from './pages/Contacts';
import { Projects } from './pages/Projects';
import { Page404 } from './pages/Page404';
import { AdminPage } from './pages/Admin';

export const routes: { path: string; component: any }[] = [
  {
    path: Paths.index,
    component: Index,
  },
  {
    path: Paths.news,
    component: News,
  },
  {
    path: Paths.aboutUs,
    component: AboutUs,
  },
  {
    path: Paths.contacts,
    component: Contacts,
  },
  {
    path: Paths.projects,
    component: Projects,
  },
  {
    path: Paths.admin,
    component: AdminPage,
  },
  {
    path: Paths.page404,
    component: Page404,
  },
];
