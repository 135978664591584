import { Col, Icon, Loader, Modal, Row } from '../../components';
import { useTranslate } from '../../hooks/useTranslate';
import moment, { Locale } from 'moment';
import { PublicDateFormat } from '../../utils/consts';
import { useState } from 'react';
import { MultiLangNewsItem, NewsItem } from '../../utils/interfaces';
import { useSaveNews } from '../../queries/useSaveNews';
import { useQueryClient } from 'react-query';
import { ModalNewsItem } from './ModalNewsItem';
import { useGetFullNews } from '../../queries/useGetFullNews';

const getNewNews = (localeList: string[]) => {
  const result: MultiLangNewsItem = {} as MultiLangNewsItem;
  localeList.forEach((item) => {
    // @ts-ignore
    result[item as unknown as Locale] = { groupId: 0, title: '', text: '', date: new Date() };
  });
  return result;
};

export const News = () => {
  const { t, locale, localeList } = useTranslate();
  const [selectedItem, setSelectedItem] = useState<MultiLangNewsItem | null>(null);
  const { isLoading, isError, data } = useGetFullNews();
  const { mutateAsync } = useSaveNews();
  const queryClient = useQueryClient();

  if (isLoading && !Array.isArray(data)) {
    return <Loader />;
  }

  if (isError) {
    return <div className='error-message-wrapper'>{t('error.title')}</div>;
  }

  const onSave = async (value: MultiLangNewsItem) => {
    await mutateAsync(value);
    setSelectedItem(value);
    await queryClient.invalidateQueries({ queryKey: ['fullNews'] });
    await queryClient.invalidateQueries({ queryKey: ['news'] });
  };

  const setNew = () => {
    const newItem = getNewNews(localeList);
    setSelectedItem(newItem);
  };

  const selectItem = (groupId: number) => {
    if (data) {
      const result: MultiLangNewsItem = {} as MultiLangNewsItem;
      for (const lang in data) {
        // @ts-ignore
        result[lang as Locale] = data[lang as Locale].find((item: NewsItem) => item.groupId === groupId) as NewsItem;
      }
      setSelectedItem(result);
    }
  };

  return (
    <>
      <Modal
        visible={selectedItem !== null}
        id={`item-edit`}
        className='item-edit news-element'
        onClose={() => setSelectedItem(null)}
      >
        <ModalNewsItem data={selectedItem} onComplete={onSave} onClose={() => setSelectedItem(null)} />
      </Modal>
      <Row>
        <Col className='new-item-wrapper'>
          <button onClick={setNew}>{t('pages.admin.news.newItem')}</button>
        </Col>
      </Row>
      <Row>
        <Col className='items-wrapper'>
          <Row>
            <Col className='date-wrapper head'>{t('pages.admin.news.table.head.date')}</Col>
            <Col className='title-wrapper head'>{t('pages.admin.news.table.head.title')}</Col>
            <Col className='action-wrapper head'></Col>
            <Col className='action-wrapper head'></Col>
          </Row>
          {data &&
            Array.isArray(data[locale]) &&
            data[locale].map((item) => (
              <Row key={item.date.toString()}>
                <Col className='date-wrapper'>{moment(item.date).format(PublicDateFormat)}</Col>
                <Col className='title-wrapper'>{item.title}</Col>
                <Col className='action-wrapper'>
                  <button className='edit' onClick={() => selectItem(item.groupId)}>
                    <Icon icon={'icon-edit'} />
                  </button>
                </Col>
                <Col className='action-wrapper'>
                  <button className='remove'>
                    <Icon icon={'icon-remove'} />
                  </button>
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
    </>
  );
};
