import { MenuItem } from './interfaces';

const Second = 1000;
export const UseMocks = false;
export const ServerApiPath = 'https://desir-savoir.com/server/api.php';
export const ServerActions = {
  upload: 'upload',
  login: 'login',
  getProjects: 'get_projects',
  getAllProjects: 'get_projects_all',
  getNews: 'get_news',
  getAllNews: 'get_news_all',
  saveNews: 'save_news',
  saveProject: 'save_project',
  checkToken: 'check_token',
  subscribe: 'subscribe',
  contactUs: 'contact_us',
};

export const StorageTokenKey = 'token';
export const TokenCheckInterval = 10 * 60 * Second;

export const Paths = {
  index: '/',
  news: '/news',
  aboutUs: '/about-us',
  contacts: '/contacts',
  projects: '/projects',
  admin: '/admode',
  page404: '*',
};

export const DesktopSizes = ['xl', 'xxl'];

export const ServerDateFormat = 'YYYY-MM-DD HH:mm:ss as const';
export const PublicDateFormat = 'DD.MM.YYYY HH:mm' as const;
export const PublicShortDateFormat = 'DD.MM.YYYY' as const;
export const InputDateFormat = 'YYYY-MM-DDThh:mm:ss';

export const MaxNewsOnPage = 5;

export const NoTranslationMessage = '___';

export const DefaultLocale = 'en';
export const Rem = 16;

export const AdditionalConfig = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  structuralSharing: false,
  keepPreviousData: false,
  fetchPolicy: 'network-only',
};

export const LanguageAliases: { [key: string]: string } = {
  'ru-RU': 'ru',
  'en-En': 'en',
};

export const SocialMediaLinks = [
  {
    icon: 'icon-x',
    link: 'https://x.com/desir_savoir',
  },
  {
    icon: 'icon-telegram',
    link: 'https://t.me/desir_savoir',
  },
  {
    icon: 'icon-vk',
    link: 'https://vk.com/desir_savoir',
  },
];

export const MenuItems: MenuItem[][] = [
  [
    {
      title: 'menuItems.home',
      path: Paths.index,
    },
    {
      title: 'menuItems.news',
      path: Paths.news,
    },
    {
      title: 'menuItems.projects',
      path: Paths.projects,
    },
  ],
  [
    {
      title: 'menuItems.aboutUs',
      path: Paths.aboutUs,
    },
    {
      title: 'menuItems.contact',
      path: Paths.contacts,
    },
    {
      title: '',
      isSpecial: true,
      icons: SocialMediaLinks,
    },
  ],
];
