import { Container, Loader, TabSwitcher } from '../../components';
import { useTranslate } from '../../hooks/useTranslate';
import { News } from './news';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Projects } from './projects';
import { useCheckToken } from '../../queries/useCheckToken';
import { TokenCheckInterval } from '../../utils/consts';
import { Login } from './login';
import { getToken } from '../../utils/helpers';

export const AdminPage = () => {
  const { t } = useTranslate();
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const tokenCheckTimeout = useRef<ReturnType<typeof setInterval> | null>(null);

  const { mutateAsync } = useCheckToken();

  const checkToken = useCallback(async (token: string) => {
    const result = await mutateAsync(token);
    setIsAuth(result);
    setIsLoading(false);
  }, []);

  const tokenChecker = useCallback(() => {
    const token = getToken();
    if (token) {
      checkToken(token);
    } else {
      setIsLoading(false);
      setIsAuth(false);
    }
  }, [checkToken]);

  const completeAuth = useCallback(
    (result: boolean) => {
      setIsAuth(result);
      setIsLoading(false);
      if (tokenCheckTimeout?.current !== null) {
        clearTimeout(tokenCheckTimeout.current);
        tokenCheckTimeout.current = null;
      }
      tokenCheckTimeout.current = setInterval(() => tokenChecker(), TokenCheckInterval);
    },
    [tokenChecker],
  );

  useEffect(() => {
    tokenChecker();
  }, [tokenChecker]);

  const tabSwitcherData = useMemo(
    () => [
      { title: t('pages.admin.news.title'), content: <News /> },
      { title: t('pages.admin.projects.title'), content: <Projects /> },
    ],
    [],
  );
  return (
    <main className='admin page'>
      <Container>
        {!isLoading ? (
          <>
            {isAuth && <TabSwitcher data={tabSwitcherData} />}
            {!isAuth && <Login onComplete={() => completeAuth(true)} />}
          </>
        ) : (
          <Loader />
        )}
      </Container>
    </main>
  );
};
