import { useQuery, UseQueryResult } from 'react-query';
import { AdditionalConfig, ServerActions, ServerApiPath, UseMocks } from '../utils/consts';
import axios from 'axios';
import MultiLangNewsMock from '../mocks/multiLanguageNews.json';
import { MultiLangNews, MultiLangNewsRaw, NewsItem } from '../utils/interfaces';
import { Locale } from '../hooks/useTranslate';
import { serializer } from './useGetNews';
import { getToken } from '../utils/helpers';

const fetch = async (): Promise<MultiLangNewsRaw> => {
  if (UseMocks) {
    return MultiLangNewsMock;
  }
  const token = getToken();
  const response = await axios.get(`${ServerApiPath}?action=${ServerActions.getAllNews}&token=${token}`);
  return response.data.results?.result;
};

export const useGetFullNews = (): UseQueryResult<MultiLangNews> => {
  return useQuery(['fullNews'], fetch, {
    select: (data) => {
      const result: MultiLangNews = {} as MultiLangNews;
      for (const lang in data) {
        result[lang as Locale] = (
          data[lang as Locale].map(serializer).filter((item) => item !== null) as NewsItem[]
        ).sort((a, b) => b.date.valueOf() - a.date.valueOf());
      }
      return result;
    },
    ...AdditionalConfig,
  });
};
