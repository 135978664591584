import { createContext, PropsWithChildren, ReactNode, useCallback, useContext, useRef, useState } from 'react';
import { PopupContextProps } from '../utils/interfaces';

const PopupContext = createContext<PopupContextProps>({
  showModal: () => {},
  hideModal: () => {},
});

export const PopupProvider = ({ children }: PropsWithChildren) => {
  const listOfContent = useRef<Map<string, ReactNode> | null>(null);
  const [version, setVersion] = useState<number>(0);

  const showModal = useCallback(
    (id: string, content: ReactNode) => {
      if (!listOfContent.current) {
        listOfContent.current = new Map<string, ReactNode>();
      }
      if (listOfContent.current.has(id)) {
        return false;
      }
      if (listOfContent.current.size === 0) {
        document.body.classList.add('no-scroll');
      }
      listOfContent.current.set(id, content);
      setVersion(version + 1);
      return true;
    },
    [version],
  );

  const hideModal = useCallback(
    (id: string) => {
      if (!listOfContent.current) {
        listOfContent.current = new Map<string, ReactNode>();
      }
      if (listOfContent.current.has(id)) {
        listOfContent.current.delete(id);
        if (listOfContent.current.size === 0) {
          document.body.classList.remove('no-scroll');
        }
        setVersion(version + 1);
        return true;
      }
      return false;
    },
    [version],
  );

  return (
    <PopupContext.Provider
      value={{
        showModal,
        hideModal,
      }}
    >
      {children}
      {Array.from(listOfContent?.current || []).map(([, content]) => {
        return content;
      })}
    </PopupContext.Provider>
  );
};

export const usePopup = () => useContext(PopupContext);
