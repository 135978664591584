import { PropsWithChildren } from 'react';

export type ColumnProps = PropsWithChildren<{
  reverse?: boolean;
  className?: string;
  span?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  style?: any;
  innerRef?: any;
}>;

export const Col = ({ children, span = 1, className = '', reverse = false, style, innerRef }: ColumnProps) => {
  const classes = `col${reverse ? ' reverse' : ''}${span !== 1 ? ' span-' + span : ''} ${className}`;
  return (
    <div className={classes} ref={innerRef} style={style}>
      {children}
    </div>
  );
};
