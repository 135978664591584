import { useQuery, UseQueryResult } from 'react-query';
import { AdditionalConfig, ServerActions, ServerApiPath, ServerDateFormat, UseMocks } from '../utils/consts';
import axios from 'axios';
import NewsMock from '../mocks/news.json';
import { NewsItem, NewsItemRaw } from '../utils/interfaces';
import moment from 'moment';
import { DetectLocale } from '../hooks/useTranslate';

const fetch = async (): Promise<NewsItemRaw[]> => {
  if (UseMocks) {
    return NewsMock;
  }
  const lang = DetectLocale();

  const response = await axios.get(`${ServerApiPath}?action=${ServerActions.getNews}&language=${lang}`);
  return response.data.results?.result;
};

export const serializer = (itemRaw: NewsItemRaw): NewsItem | null => {
  const date = moment(itemRaw.date, ServerDateFormat);
  if (date.isValid()) {
    return {
      id: itemRaw.id,
      groupId: itemRaw.groupId,
      title: itemRaw.title,
      text: itemRaw.text,
      date: date.toDate(),
    };
  }

  return null;
};

export const useGetNews = (): UseQueryResult<NewsItem[]> => {
  return useQuery(['news'], () => fetch(), {
    select: (data) =>
      (data.map(serializer).filter((item) => item !== null) as NewsItem[]).sort(
        (a, b) => b.date.valueOf() - a.date.valueOf(),
      ),
    ...AdditionalConfig,
  });
};
