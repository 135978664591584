import { Col, Row, Modal } from '../../components';
import moment from 'moment';
import { PublicShortDateFormat } from '../../utils/consts';
import { ProjectItem } from '../../utils/interfaces';
import { useTranslate } from '../../hooks/useTranslate';
import Markdown from 'react-markdown';
import { ImagesBlock } from './ImagesBlock';

interface ModalProps extends Omit<ProjectItem, 'id'> {
  id: string;
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
}

export const ProjectModal = ({
  id,
  title,
  mainImage,
  images,
  video,
  description,
  dateStart,
  datePublish,
  links,
  page,
  modalVisible,
  setModalVisible,
}: ModalProps) => {
  const { t } = useTranslate();

  return (
    <Modal id={id} visible={modalVisible} onClose={() => setModalVisible(false)} header={title}>
      <Row className='roller'>
        <Col className='main-wrapper'>
          <Row className='main-image-video'>
            <Col>
              {video ? (
                <video autoPlay muted controls>
                  <source src={video} type='video/mp4' />
                </video>
              ) : (
                <img src={mainImage} alt={title} />
              )}
            </Col>
          </Row>

          <Row>
            <Col className='dates'>
              <p>{t('pages.projects.modal.start', { date: moment(dateStart).format(PublicShortDateFormat) })}</p>
              {datePublish && (
                <p>{t('pages.projects.modal.publish', { date: moment(datePublish).format(PublicShortDateFormat) })}</p>
              )}
            </Col>
          </Row>
          <Row>
            <Col className='navigate-buttons'>
              <button
                onClick={() => {
                  if (page) {
                    window.open(page, '_blank');
                  }
                }}
              >
                {page ? t('pages.projects.modal.openProject') : t('pages.projects.modal.projectPageUnavailable')}
              </button>
            </Col>
          </Row>
        </Col>
        <Col className='description-wrapper'>
          <Row>
            <Col className='description'>
              <Markdown>{description}</Markdown>
            </Col>
          </Row>
          {links?.length > 0 && (
            <Row>
              <Col className='links'>
                {links?.map((item) => (
                  <a rel='noreferrer' href={item.path} key={item.path} target='_blank'>
                    {item.title}
                  </a>
                ))}
              </Col>
            </Row>
          )}
        </Col>
        <ImagesBlock images={images} />
      </Row>
    </Modal>
  );
};
