import { MultiLangNewsItem } from '../../utils/interfaces';
import { Locale, useTranslate } from '../../hooks/useTranslate';
import { useCallback, useEffect, useState } from 'react';
import { copy, getLangListByKey } from '../../utils/helpers';
import { Col, MultiLangInput, Row } from '../../components';
import moment from 'moment/moment';

export const ModalNewsItem = ({
  data,
  onComplete,
  onClose,
}: {
  data: MultiLangNewsItem | null;
  onClose: () => void;
  onComplete: (value: MultiLangNewsItem) => void;
}) => {
  const { t } = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<MultiLangNewsItem | null>(copy(data));

  useEffect(() => {
    if (data !== null && value === null) {
      setValue(data);
    }
  }, [data, value]);

  if (value === null) {
    return null;
  }

  const Update = useCallback(
    (lang: Locale, key: string, dataValue: string | number | Date | undefined) => {
      if (value) {
        const newItem = copy(value);
        newItem[lang] = { ...newItem[lang], [key]: dataValue };
        setValue(newItem);
      }
    },
    [value],
  );

  return (
    <>
      <Row>
        <Col>
          <MultiLangInput
            onChange={(language, newValue) => Update(language, 'title', newValue)}
            values={getLangListByKey(value, 'title')}
            title={t('pages.admin.news.table.head.title')}
            type='text'
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <MultiLangInput
            onChange={(language, newValue) => Update(language, 'date', moment(newValue).toDate())}
            values={getLangListByKey(value, 'date')}
            title={t('pages.admin.news.table.head.date')}
            type='datetime-local'
          />
        </Col>
      </Row>
      <Row className='content-wrapper'>
        <Col>
          <MultiLangInput
            onChange={(language, newValue) => Update(language, 'text', newValue)}
            values={getLangListByKey(value, 'text')}
            title={t('pages.admin.news.table.head.content')}
            type='textarea'
          />
        </Col>
      </Row>
      <Row className='buttons-wrapper'>
        <Col>
          <button disabled={isLoading} onClick={onClose}>
            {t('pages.admin.news.cancel')}
          </button>
        </Col>
        <Col>
          <button
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true);
              onComplete(value as MultiLangNewsItem);
            }}
          >
            {t('pages.admin.news.save')}
          </button>
        </Col>
      </Row>
    </>
  );
};
