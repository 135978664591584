import axios from 'axios';
import { ServerActions, ServerApiPath, UseMocks } from '../utils/consts';
import { useMutation, UseMutationResult } from 'react-query';

const fetch = async (token: string): Promise<boolean> => {
  if (UseMocks) {
    return true;
  }

  const response = await axios.post(`${ServerApiPath}?action=${ServerActions.checkToken}`, { token });
  return response.data?.results === true;
};

export const useCheckToken = (): UseMutationResult<boolean, unknown, string, unknown> => {
  return useMutation((token: string) => fetch(token));
};
