import { Col, Icon, Loader, Modal, Row } from '../../components';
import { useTranslate } from '../../hooks/useTranslate';
import { useState } from 'react';
import { MultiLangProjectItem, NewsItem } from '../../utils/interfaces';
import { useQueryClient } from 'react-query';
import { useSaveProject } from '../../queries/useSaveProject';
import { ModalProjectItem } from './ModalProjectItem';
import { useGetFullProjects } from '../../queries/useGetFullProjects';
import { Locale } from 'moment/moment';

const getNewProject = (localeList: string[]) => {
  const result: MultiLangProjectItem = {} as MultiLangProjectItem;
  localeList.forEach((item) => {
    // @ts-ignore
    result[item as unknown as Locale] = {
      groupId: 0,
      title: '',
      images: [],
      mainImage: '',
      description: '',
      dateStart: new Date(),
      links: [],
      shortDescription: '',
    };
  });
  return result;
};

export const Projects = () => {
  const { t, locale, localeList } = useTranslate();
  const [selectedItem, setSelectedItem] = useState<MultiLangProjectItem | null>(null);
  const { isLoading, isError, data } = useGetFullProjects();
  const { mutateAsync } = useSaveProject();
  const queryClient = useQueryClient();

  if (isLoading && !Array.isArray(data)) {
    return <Loader />;
  }

  if (isError) {
    return <div className='error-message-wrapper'>{t('error.title')}</div>;
  }

  const onSave = async (value: MultiLangProjectItem) => {
    await mutateAsync(value);
    setSelectedItem(value);
    await queryClient.invalidateQueries({ queryKey: ['fullProjects'] });
    await queryClient.invalidateQueries({ queryKey: ['projects'] });
  };

  const selectItem = (groupId: number) => {
    if (data) {
      const result: MultiLangProjectItem = {} as MultiLangProjectItem;
      // @ts-ignore
      for (const lang in data) {
        // @ts-ignore
        result[lang as Locale] = data[lang as Locale].find((item: NewsItem) => item.groupId === groupId) as NewsItem;
      }
      setSelectedItem(result);
    }
  };

  const setNew = () => {
    const newItem = getNewProject(localeList);
    setSelectedItem(newItem);
  };

  return (
    <>
      <Modal
        visible={selectedItem !== null}
        id={`item-edit`}
        className='item-edit project'
        onClose={() => setSelectedItem(null)}
      >
        <ModalProjectItem data={selectedItem} onComplete={onSave} onClose={() => setSelectedItem(null)} />
      </Modal>
      <Row>
        <Col className='new-item-wrapper'>
          <button onClick={setNew}>{t('pages.admin.projects.newItem')}</button>
        </Col>
      </Row>
      <Row>
        <Col className='items-wrapper'>
          <Row>
            <Col className='title-wrapper head'>{t('pages.admin.projects.table.head.title')}</Col>
            <Col className='action-wrapper head'></Col>
            <Col className='action-wrapper head'></Col>
          </Row>
          {data &&
            Array.isArray(data[locale]) &&
            data[locale].map((item) => (
              <Row key={item.id}>
                <Col className='title-wrapper'>{item.title}</Col>
                <Col className='action-wrapper'>
                  <button className='edit' onClick={() => selectItem(item.groupId)}>
                    <Icon icon={'icon-edit'} />
                  </button>
                </Col>
                <Col className='action-wrapper'>
                  <button className='remove'>
                    <Icon icon={'icon-remove'} />
                  </button>
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
    </>
  );
};
