import { Row, Col, Logo, Icon, Dropdown, Modal } from './';
import { DesktopSizes, MenuItems } from '../utils/consts';
import { NavLink } from 'react-router-dom';
import { MenuItem } from '../utils/interfaces';
import { Locale, useTranslate } from '../hooks/useTranslate';
import { useBreakpoint } from '../hooks/useBreakpoint';
import { JSXElementConstructor, useState } from 'react';

interface MenuItemWithCoverTo extends MenuItem {
  coverTo?: JSXElementConstructor<any>;
  onClick?: () => void;
}

const SpecialMenuItem = (item: MenuItem) => {
  return (
    <>
      {item.icons?.map((iconElement) => (
        <MenuElement key={iconElement.link} title={''} icon={iconElement.icon} link={iconElement.link} />
      ))}
    </>
  );
};

const MenuElement = (item: MenuItemWithCoverTo) => {
  const { t } = useTranslate();
  if (item.isSpecial) {
    if (item.coverTo) {
      const Component = item.coverTo;
      return (
        <Component>
          <SpecialMenuItem {...item} />
        </Component>
      );
    }
    return <SpecialMenuItem {...item} />;
  }
  let targetPath = '#';
  let target = undefined;
  if (item.path) {
    targetPath = item.path;
  }
  if (item.link) {
    targetPath = item.link;
    target = '_blank';
  }
  const result = (
    <NavLink
      target={target}
      className={`col center ${item.icon ? 'is-icon' : ''}`}
      to={targetPath}
      title={t(item.title)}
      onClick={item.onClick}
    >
      {item.icon ? <Icon icon={item.icon} /> : t(item.title)}
    </NavLink>
  );

  if (item.coverTo) {
    const Component = item.coverTo;
    return <Component>{result}</Component>;
  }
  return result;
};

export const MobileMenu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  return (
    <>
      <button className='burger' onClick={() => setIsMenuVisible(true)}>
        <div />
        <div />
        <div />
      </button>
      <Modal
        options={{ level: 10 }}
        visible={isMenuVisible}
        className='burger-menu'
        id={'burger-menu'}
        onClose={() => setIsMenuVisible(false)}
      >
        <div className='center'>
          <Row className='flex-no-wrap'>
            <Col className='center flex'>
              <Logo withWrap={true} />
            </Col>
          </Row>
        </div>
        {MenuItems[0].map((item) => (
          <MenuElement key={item.title} {...item} coverTo={Row} onClick={() => setIsMenuVisible(false)} />
        ))}
        {MenuItems[1].map((item) => (
          <MenuElement key={item.title} {...item} coverTo={Row} onClick={() => setIsMenuVisible(false)} />
        ))}
      </Modal>
    </>
  );
};

export const Header = () => {
  const { localeList, changeLocale, locale } = useTranslate();
  const { breakpoint } = useBreakpoint();
  const isDesktop = DesktopSizes.includes(breakpoint);

  return (
    <header className={`row flex-no-wrap ${!isDesktop ? 'center' : ''}`}>
      {!isDesktop && <MobileMenu />}
      {isDesktop && (
        <Col className='center'>
          <Row className='flex-no-wrap center'>
            {MenuItems[0].map((item) => (
              <MenuElement key={item.title} {...item} />
            ))}
          </Row>
        </Col>
      )}
      <Col className='logo-wrapper'>
        <Row className='flex-no-wrap'>
          <Col className='center'>
            <Logo withWrap={true} />
          </Col>
        </Row>
      </Col>
      {isDesktop && (
        <Col className='center'>
          <Row className='flex-no-wrap'>
            {MenuItems[1].map((item) => (
              <MenuElement key={item.title} {...item} />
            ))}
          </Row>
        </Col>
      )}
      <Dropdown
        className='change-language'
        data={localeList.map((item) => ({
          title: item.toString(),
          value: item.toString(),
        }))}
        value={locale.toString()}
        onChange={(newLocale) => changeLocale(newLocale as Locale)}
        itemClassName={'change-language-item'}
      />
    </header>
  );
};
