import { useGetNews } from '../../queries/useGetNews';
import { Container, Loader, ParticlesElement } from '../../components';
import { useTranslate } from '../../hooks/useTranslate';
import { useState } from 'react';
import { DesktopSizes } from '../../utils/consts';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { Desktop } from './desktop';
import { Mobile } from './mobile';

const DataContainer = () => {
  const [activeItem, setActiveItem] = useState<number>(0);
  const { t } = useTranslate();
  const { isLoading, isError, data } = useGetNews();
  const { breakpoint } = useBreakpoint();
  const isDesktop = DesktopSizes.includes(breakpoint);

  return (
    <>
      {isLoading && (
        <div className='loading-wrapper'>
          <Loader />
        </div>
      )}
      {isError && (
        <div className='error-wrapper'>
          <h2>{t('error.title')}</h2>
          <p>{t('error.network')}</p>
        </div>
      )}
      {!isError && !isLoading && Array.isArray(data) && (
        <>
          {isDesktop ? (
            <Desktop data={data} activeItem={activeItem} setActiveItem={setActiveItem} />
          ) : (
            <Mobile data={data} activeItem={activeItem} setActiveItem={setActiveItem} />
          )}
        </>
      )}
    </>
  );
};

export const News = () => {
  return (
    <main className='page news'>
      <Container className='center'>
        <ParticlesElement className='background-position' />
        <DataContainer />
      </Container>
    </main>
  );
};
