import { ReactNode, useState } from 'react';
import { Row } from './Row';
import { Col } from './Col';

interface Tab {
  title: string;
  content: ReactNode;
}

interface TabProps {
  data: Tab[];
  className?: string;
  onChange?: (value: number) => void;
}

export const TabSwitcher = ({ data, className = '', onChange = () => {} }: TabProps) => {
  const [active, setActive] = useState<number>(0);

  return (
    <Row className={`tab-wrapper ${className}`}>
      <Col>
        <Row className='tabs'>
          {data.map((item, index) => (
            <Col key={item.title} className={`tab-key-wrapper ${index === active ? 'active' : ''}`}>
              <button
                className='tab-key'
                onClick={() => {
                  setActive(index);
                  onChange(index);
                }}
              >
                {item.title}
              </button>
            </Col>
          ))}
        </Row>
        <Row className='content'>
          <Col className='content-wrapper'>{data.length > active ? data[active].content : null}</Col>
        </Row>
      </Col>
    </Row>
  );
};
