import { useTranslate } from '../../hooks/useTranslate';
import { useState } from 'react';
import { Col, Container, HR, Icon, Row, SubmitInput } from '../../components';
import { validateEmail } from '../../utils/validators';
import GG from '../../assets/gg.jpg';
import MP from '../../assets/mp.jpg';
import { useSubscribe } from '../../queries/useSubscribe';

export const Index = () => {
  const { t } = useTranslate();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isSending, setIsSending] = useState<boolean>(false);
  const { mutateAsync } = useSubscribe();

  let submitIcon = 'icon-send';
  if (!isValid) {
    submitIcon = 'icon-deny';
  }
  if (isSending) {
    submitIcon = 'icon-in-progress';
  }

  const subscribe = async (email: string) => {
    if (isValid && email.trim() !== '') {
      setIsSending(true);
      await mutateAsync(email);
      setIsSending(false);
    }
  };

  return (
    <main className='page index'>
      <div className='background'>
        <div className='subscribe-wrapper'>
          <div className='title'>{t('subscribe.title')}</div>
          <div className='content'>{t('subscribe.content')}</div>
          <SubmitInput
            onSubmit={subscribe}
            type={'email'}
            onValidate={(email) => {
              if (email === '') {
                setIsValid(true);
              }
              setIsValid(validateEmail(email));
            }}
            placeholder='email@example.com'
            submitTitle={<Icon icon={submitIcon} />}
          />
          <div className='subtext'>{t('subscribe.subtext')}</div>
        </div>
      </div>
      <HR />
      <Container className='index-main-container'>
        <Row className='items as-grid'>
          <Col className='img-wrapper'>
            <img src={GG} alt={''} />
          </Col>
          <Col className='text-wrapper'>
            <h2 className='text-glowing'>{t('pages.index.blocks.first.title')}</h2>
            <p>{t('pages.index.blocks.first.text')}</p>
          </Col>
        </Row>
        <Row className='items as-grid'>
          <Col className='text-wrapper'>
            <h2 className='text-glowing'>{t('pages.index.blocks.second.title')}</h2>
            <p>{t('pages.index.blocks.second.text')}</p>
          </Col>
          <Col className='img-wrapper'>
            <img src={MP} alt={''} />
          </Col>
        </Row>
      </Container>
    </main>
  );
};
