import { Col, Container, Row } from '../../components';
import { useTranslate } from '../../hooks/useTranslate';
import Markdown from 'react-markdown';
import AboutImg from '../../assets/about.jpg';
import { NavLink } from 'react-router-dom';
import { Paths } from '../../utils/consts';

export const AboutUs = () => {
  const { t } = useTranslate();
  return (
    <main className='page about-us'>
      <Container>
        <Row>
          <Col className='wrapper'>
            <img src={AboutImg} alt='about us' />
            <Markdown>{t('pages.aboutUs.content')}</Markdown>
            <div className='link-wrapper'>
              <NavLink to={Paths.contacts}>{t('pages.aboutUs.contactLink')}</NavLink>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};
