import { ReactNode, useEffect, useState } from 'react';

interface PaginationProps {
  direction?: 'vertical' | 'horizontal';
  items: ReactNode[];
  maxToShow: number;
  active: number;
  useOffset?: boolean;
  onPrev?: () => void;
  onNext?: () => void;
  prevText: ReactNode;
  nextText: ReactNode;
}

export const Pagination = ({
  direction = 'horizontal',
  items,
  maxToShow,
  active,
  useOffset = false,
  onPrev = () => {},
  onNext = () => {},
  prevText,
  nextText,
}: PaginationProps) => {
  const calculatedMax = items.length < maxToShow ? items.length : maxToShow;
  let showBefore = Math.round((calculatedMax - 1) / 2);
  let showAfter = calculatedMax - 1 - showBefore;
  const [offset, setOffset] = useState<number>(0);
  useEffect(() => {
    if (useOffset) {
      setOffset(0);
    }
  }, [active]);

  if (active < showBefore) {
    showAfter += showBefore - active;
    showBefore = active;
  }
  if (active > items.length - 1 - showAfter) {
    showBefore -= items.length - 1 - showAfter - active;
    showAfter = items.length - 1 - active;
  }
  let listIndexes = [active];
  if (showBefore > 0) {
    for (let i = active - 1; i > active - 1 - showBefore; i--) {
      listIndexes.push(i);
    }
  }
  if (showAfter > 0) {
    for (let i = active + 1; i < active + 1 + showAfter; i++) {
      listIndexes.push(i);
    }
  }

  if (offset !== 0) {
    listIndexes = listIndexes.map((index) => index + offset);
  }
  const sortedListIndexes = listIndexes.sort((a, b) => a - b);
  const lowerItem = sortedListIndexes[0];
  const higherItem = sortedListIndexes[sortedListIndexes.length - 1];
  const makeOffset = (value: number) => {
    if ((value > 0 && higherItem < items.length - 1) || (value < 0 && lowerItem > 0)) {
      setOffset(offset + value);
    }
  };
  return (
    <div className={`pagination ${direction.toString()}`}>
      <button
        className='prev'
        disabled={lowerItem === 0}
        onClick={() => {
          if (useOffset) {
            makeOffset(-1);
          } else {
            onPrev();
          }
        }}
      >
        {prevText}
      </button>
      <div className={`items-list ${direction.toString()}`}>
        {items.map((item, index) => {
          if (listIndexes.includes(index)) {
            return item;
          }
          return null;
        })}
      </div>
      <button
        className='next'
        disabled={higherItem === items.length - 1}
        onClick={() => {
          if (useOffset) {
            makeOffset(1);
          } else {
            onNext();
          }
        }}
      >
        {nextText}
      </button>
    </div>
  );
};
