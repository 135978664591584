import { useRef } from 'react'

interface DropdownProps {
  data: { title: string; value: string }[]
  value: string
  onChange: (value: string) => void
  className?: string
  itemClassName?: string
}

export const Dropdown = ({ data, value, onChange, className, itemClassName }: DropdownProps) => {
  const active = data.find((i) => i.value === value)
  const dropdownRef = useRef<HTMLButtonElement>(null)

  const onClick = () => {
    if (dropdownRef?.current) {
      const overflow = document.createElement('div')
      overflow.className = 'dropdown-overflow'
      const rect = dropdownRef.current.getBoundingClientRect()
      const positionTop = rect.top + rect.height
      const positionLeft = rect.left
      const wrapper = document.createElement('div')
      const setWrapperPosition = () => {
        const wrapperRect = wrapper.getBoundingClientRect()
        const width = window.innerWidth
        const maxLeftPosition = width - wrapperRect.width
        if (positionLeft > maxLeftPosition) {
          wrapper.style.left = maxLeftPosition + 'px'
        } else {
          wrapper.style.left = positionLeft + 'px'
        }
        wrapper.style.top = positionTop + 'px'
      }
      wrapper.className = 'dropdown-wrapper'
      let elements: HTMLElement[] = []
      const onOverflowClick = (e: MouseEvent) => {
        e.preventDefault()
        elements.forEach((i) => {
          i.removeEventListener('click', onClick)
          i.remove()
        })
        overflow.removeEventListener('click', onOverflowClick)
        overflow.remove()
        wrapper.remove()
        window.removeEventListener('resize', setWrapperPosition)
      }
      const onClick = (e: MouseEvent) => {
        e.preventDefault()
        const value = (e.target as HTMLElement).getAttribute('data-x-value')
        elements.forEach((i) => {
          i.removeEventListener('click', onClick)
          i.remove()
        })
        overflow.removeEventListener('click', onOverflowClick)
        overflow.remove()
        wrapper.remove()
        window.removeEventListener('resize', setWrapperPosition)
        onChange(value as string)
      }
      elements = data.map((item) => {
        const element = document.createElement('div')
        element.setAttribute('data-x-value', item.value)
        element.className = `dropdown-item ${itemClassName}`
        element.innerText = item.title
        element.addEventListener('click', onClick)
        wrapper.appendChild(element)
        return element
      })
      overflow.addEventListener('click', onOverflowClick)
      document.body.appendChild(overflow)
      document.body.appendChild(wrapper)
      setWrapperPosition()
      window.addEventListener('resize', setWrapperPosition)
    }
  }
  return (
    <button ref={dropdownRef} onClick={onClick} className={`dropdown ${className}`}>
      {active?.title}
    </button>
  )
}
