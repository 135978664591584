import axios from 'axios';
import { ServerActions, ServerApiPath, UseMocks } from '../utils/consts';
import { useMutation, UseMutationResult } from 'react-query';

interface LoginData {
  login: string;
  password: string;
}

const fetch = async (data: LoginData): Promise<string> => {
  if (UseMocks) {
    return 'token';
  }

  const response = await axios.post(`${ServerApiPath}?action=${ServerActions.login}`, data);
  return response.data?.results?.token;
};

export const useLogin = (): UseMutationResult<string, unknown, LoginData, unknown> => {
  return useMutation((data: LoginData) => fetch(data));
};
