import { MultiLangProjectItem, SubContentByLang } from '../../utils/interfaces';
import { Locale, useTranslate } from '../../hooks/useTranslate';
import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import { copy, getLangListByKey } from '../../utils/helpers';
import { useUpload } from '../../queries/useUpload';
import moment from 'moment/moment';
import { Col, Icon, Loader, MultiLangInput, MultiLangMultiInput, Row } from '../../components';

export const ModalProjectItem = ({
  data,
  onComplete,
  onClose,
}: {
  data: MultiLangProjectItem | null;
  onClose: () => void;
  onComplete: (value: MultiLangProjectItem) => void;
}) => {
  const { t } = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<MultiLangProjectItem | null>(copy(data));
  const [isFileLoading, setIsFileLoading] = useState(false);
  const { mutateAsync } = useUpload();

  useEffect(() => {
    if (data !== null && value === null) {
      setValue(data);
    }
  }, [data, value]);

  const uploadFile = (e: ChangeEvent<HTMLInputElement>): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!e.target.files?.length) {
        reject();
        return;
      }

      setIsFileLoading(true);
      const file = e.target.files[0];
      const loader = new FileReader();

      loader.addEventListener('load', async (event: ProgressEvent<FileReader>) => {
        if (event?.target) {
          if (event.target.readyState !== 2) return;
          if (event.target.error) {
            alert('Error while reading file');
            setIsFileLoading(false);
            reject();
            return;
          }

          const nameArr = file.name.split('.');
          const fileExtension = nameArr[nameArr.length - 1].toLowerCase();

          const allowedExtensions = ['jpg', 'png', 'mp4', 'mov', 'avi'];
          if (!allowedExtensions.includes(fileExtension)) {
            alert('Unsupported file format');
            setIsFileLoading(false);
            reject();
            return;
          }

          const fileContent = event.target.result as string;
          if (fileContent) {
            try {
              const result = await mutateAsync({
                extension: fileExtension,
                data: fileContent,
              });
              resolve(result);
            } catch (error) {
              alert('Error while uploading file');
              setIsFileLoading(false);
              reject();
              return;
            }
          }
          setIsFileLoading(false);
        }
      });

      loader.readAsDataURL(file);
    });
  };

  const removeImage = (lang: Locale, index: number) => {
    if (value) {
      if (value[lang].images.length && value[lang].images.length > index) {
        const newValue = copy(value);
        newValue[lang].images.splice(index, 1);
        setValue(newValue);
      }
    }
  };
  const removeLink = (lang: Locale, index: number) => {
    if (value) {
      if (value[lang].links.length && value[lang].links.length > index) {
        const newValue = copy(value);
        newValue[lang].links.splice(index, 1);
        setValue(newValue);
      }
    }
  };

  const addImage = async (lang: Locale, e: ChangeEvent<HTMLInputElement>) => {
    if (value) {
      try {
        const path = await uploadFile(e);
        const newValue = copy(value);
        newValue[lang].images.push(path);
        setValue(newValue);
      } catch (e) {
        console.error('upload error', e);
      }
    }
  };

  const setMainImage = async (lang: Locale, e: ChangeEvent<HTMLInputElement>) => {
    if (value) {
      try {
        const path = await uploadFile(e);
        const newValue = copy(value);
        newValue[lang].mainImage = path;
        setValue(newValue);
      } catch (e) {
        console.error('upload error', e);
      }
    }
  };

  const setMainVideo = async (lang: Locale, e: ChangeEvent<HTMLInputElement>) => {
    if (value) {
      try {
        const path = await uploadFile(e);
        const newValue = copy(value);
        newValue[lang].video = path;
        setValue(newValue);
      } catch (e) {
        console.error('upload error', e);
      }
    }
  };

  const Update = useCallback(
    (lang: Locale, key: string, dataValue: string | number | Date | undefined) => {
      if (value) {
        const newItem = copy(value);
        newItem[lang] = { ...newItem[lang], [key]: dataValue };
        setValue(newItem);
      }
    },
    [value],
  );

  const addLink = useCallback(
    (lang: Locale, dataValue: string[]) => {
      if (value && dataValue.length > 1) {
        const newItem = copy(value);
        newItem[lang].links.push({ title: dataValue[0], path: dataValue[1] });
        setValue(newItem);
      }
    },
    [value],
  );

  const getSubContentByLang = (callback: (lang: Locale, value: any) => ReactNode) => {
    let result = {} as SubContentByLang;
    for (const lang in value) {
      result[lang as Locale] = callback(lang as Locale, value[lang as Locale]);
    }

    return result;
  };

  if (value === null) {
    return null;
  }
  console.log(value);

  return (
    <>
      <Row>
        <Col>
          <MultiLangInput
            onChange={(language, newValue) => Update(language, 'title', newValue)}
            values={getLangListByKey(value, 'title')}
            title={t('pages.admin.projects.table.head.title')}
            type='text'
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <MultiLangInput
            onChange={(language, newValue) => Update(language, 'page', newValue)}
            values={getLangListByKey(value, 'page')}
            title={t('pages.admin.projects.table.head.page')}
            type='text'
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <MultiLangInput
            onChange={(language, newValue) => {
              const rawDate = moment(newValue);
              const result = rawDate.isValid() ? rawDate.toDate() : undefined;

              Update(language, 'datePublish', result);
            }}
            values={getLangListByKey(value, 'datePublish')}
            title={t('pages.admin.projects.table.head.publishDate')}
            type='datetime-local'
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <MultiLangInput
            onChange={(language, newValue) => Update(language, 'startDate', moment(newValue).toDate())}
            values={getLangListByKey(value, 'dateStart')}
            title={t('pages.admin.projects.table.head.startDate')}
            type='text'
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {isFileLoading && (
            <Row>
              <Col>
                <Loader />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <MultiLangInput
                disabled={isFileLoading}
                onChange={(language, value, e) => setMainImage(language, e as ChangeEvent<HTMLInputElement>)}
                title={t('pages.admin.projects.table.head.addMainImage')}
                type='file'
                subContentByLang={getSubContentByLang((lang) => (
                  <a key={lang} href={value[lang].mainImage} rel='noreferrer' target='_blank'>
                    {`${lang}: `}
                    {value[lang].mainImage}
                  </a>
                ))}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          {isFileLoading && (
            <Row>
              <Col>
                <Loader />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <MultiLangInput
                disabled={isFileLoading}
                onChange={(language, value, e) => setMainVideo(language, e as ChangeEvent<HTMLInputElement>)}
                title={t('pages.admin.projects.table.head.addMainVideo')}
                type='file'
                subContentByLang={getSubContentByLang((lang) => (
                  <a key={lang} href={value[lang].video} rel='noreferrer' target='_blank'>
                    {`${lang}: `}
                    {value[lang].video}
                  </a>
                ))}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          {isFileLoading && (
            <Row>
              <Col>
                <Loader />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <MultiLangInput
                disabled={isFileLoading}
                onChange={(language, value, e) => addImage(language, e as ChangeEvent<HTMLInputElement>)}
                title={t('pages.admin.projects.table.head.addImage')}
                type='file'
                subContentByLang={getSubContentByLang((lang) => (
                  <Row className='lang-list-of-images'>
                    <Col>
                      <Row>
                        <Col className='lang-key'>{lang}:</Col>
                      </Row>
                      {value[lang].images.map((image, index) => (
                        <Row key={image} className='image-content-wrapper'>
                          <Col className='image-action'>
                            <button disabled={isFileLoading} onClick={() => removeImage(lang, index)}>
                              <Icon icon={'icon-remove'} />
                            </button>
                          </Col>
                          <Col className='image-path'>
                            <a href={image} rel='noreferrer' target='_blank'>
                              {image}
                            </a>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                ))}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='content-wrapper short'>
        <Col>
          <MultiLangInput
            onChange={(language, newValue) => Update(language, 'shortDescription', newValue)}
            values={getLangListByKey(value, 'shortDescription')}
            title={t('pages.admin.projects.table.head.shortDescription')}
            type='textarea'
          />
        </Col>
      </Row>
      <Row className='content-wrapper full'>
        <Col>
          <MultiLangInput
            onChange={(language, newValue) => Update(language, 'description', newValue)}
            values={getLangListByKey(value, 'description')}
            title={t('pages.admin.projects.table.head.description')}
            type='textarea'
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <MultiLangMultiInput
            onAdd={(language, value) => addLink(language, value)}
            titles={[t('pages.admin.projects.table.head.linkTitle'), t('pages.admin.projects.table.head.linkPath')]}
            buttonTitle={t('pages.admin.projects.table.head.addLink')}
            mainTitle={t('pages.admin.projects.table.head.listOfLinks')}
            types={['text', 'text']}
            subContentByLang={getSubContentByLang((lang) => (
              <Row className='lang-list-of-images'>
                <Col>
                  <Row>
                    <Col className='lang-key'>{lang}:</Col>
                  </Row>
                  {value[lang].links.map((link, index) => (
                    <Row key={link.path} className='image-content-wrapper'>
                      <Col className='image-action'>
                        <button onClick={() => removeLink(lang, index)}>
                          <Icon icon={'icon-remove'} />
                        </button>
                      </Col>
                      <Col className='image-path'>
                        <a href={link.path} rel='noreferrer' target='_blank'>
                          {`${link.title} (${link.path})`}
                        </a>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            ))}
          />
        </Col>
      </Row>
      <Row className='buttons-wrapper'>
        <Col>
          <button disabled={isLoading} onClick={onClose}>
            {t('pages.admin.projects.cancel')}
          </button>
        </Col>
        <Col>
          <button
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true);
              onComplete(value as MultiLangProjectItem);
            }}
          >
            {t('pages.admin.projects.save')}
          </button>
        </Col>
      </Row>
    </>
  );
};
