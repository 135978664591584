import axios from 'axios';
import { ServerActions, ServerApiPath, UseMocks } from '../utils/consts';
import { useMutation, UseMutationResult } from 'react-query';

interface ContactUsForm {
  name: string;
  email: string;
  text: string;
}

const fetch = async (data: ContactUsForm): Promise<void> => {
  if (UseMocks) {
    return;
  }

  await axios.post(`${ServerApiPath}?action=${ServerActions.contactUs}`, data);
  return;
};

export const useContactUs = (): UseMutationResult<void, unknown, ContactUsForm, unknown> => {
  return useMutation((data: ContactUsForm) => fetch(data));
};
