import { Col, Icon, Pagination, Row } from '../../components';
import { Item } from './item';
import { MaxNewsOnPage } from '../../utils/consts';
import { ContentNewsProps } from '../../utils/interfaces';

export const Desktop = ({ data, activeItem, setActiveItem }: ContentNewsProps) => {
  return (
    <Row className='content'>
      <Col className='active-element'>
        <Item {...data[activeItem]} opened={true} />
      </Col>
      <Col className='list'>
        <Pagination
          items={data.map((item, index) => (
            <Item
              {...item}
              title={item.title}
              key={item.date.toString()}
              active={index === activeItem}
              onClick={() => setActiveItem(index)}
            />
          ))}
          prevText={<Icon icon='icon-up' />}
          nextText={<Icon icon='icon-down' />}
          maxToShow={MaxNewsOnPage}
          active={activeItem}
          direction={'vertical'}
          useOffset={true}
        />
      </Col>
    </Row>
  );
};
