import { Container, Loader } from '../../components';
import { useGetProjects } from '../../queries/useGetProjects';
import { useTranslate } from '../../hooks/useTranslate';
import { ItemContent } from './itemContent';

export const Projects = () => {
  const { t } = useTranslate();
  const { isLoading, isError, data } = useGetProjects();

  return (
    <main className='page projects'>
      <Container>
        {isLoading && (
          <div className='loading-wrapper'>
            <Loader />
          </div>
        )}
        {isError && (
          <div className='error-wrapper'>
            <h2>{t('error.title')}</h2>
            <p>{t('error.network')}</p>
          </div>
        )}
        {!isError &&
          !isLoading &&
          Array.isArray(data) &&
          data.map((item) => <ItemContent key={item.title} {...item} />)}
      </Container>
    </main>
  );
};
