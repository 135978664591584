import { Row } from './Row';
import { Col } from './Col';
import { Locale, useTranslate } from '../hooks/useTranslate';
import { ChangeEvent, useState } from 'react';
import { Input } from './Input';
import { MultiLangString, SubContentByLang } from '../utils/interfaces';

export interface MultiLangInputProps {
  values?: MultiLangString;
  onChange: (lang: Locale, value: string, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  title: string;
  type: string;
  disabled?: boolean;
  subContentByLang?: SubContentByLang;
}

export const MultiLangInput = ({
  subContentByLang,
  values,
  onChange,
  title,
  type,
  disabled = false,
}: MultiLangInputProps) => {
  const { localeList } = useTranslate();
  const [activeLocale, setActiveLocale] = useState<Locale>(localeList[0]);
  const OnChangeAction = (value: string, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    onChange(activeLocale, value, e);

  let value = '';
  if (values && values[activeLocale]) {
    value = values[activeLocale] as string;
  }

  return (
    <Row className='multi-lang-input'>
      <Col>
        {subContentByLang &&
          Object.keys(subContentByLang).map((lang) => (
            <Row useSpan={true} key={lang} className='sub-content-item'>
              <Col>{subContentByLang[lang as Locale]}</Col>
            </Row>
          ))}
        <Row>
          <Col className='flex-col'>
            <Input
              beforeTitle={
                <Row className='locale-list-wrapper'>
                  {localeList.map((item) => (
                    <Col key={item}>
                      <button
                        className={`${item === activeLocale ? 'active' : ''}`}
                        onClick={() => setActiveLocale(item)}
                      >
                        {item}
                      </button>
                    </Col>
                  ))}
                </Row>
              }
              onChange={OnChangeAction}
              title={title}
              disabled={disabled}
              type={type}
              value={value}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
