import { ProjectItem } from '../../utils/interfaces';
import { useMemo, useState } from 'react';
import { useTranslate } from '../../hooks/useTranslate';
import { getHash } from '../../utils/helpers';
import { Col, Row } from '../../components';
import { ProjectModal } from './modal';

export const ItemContent = ({
  title,
  mainImage,
  shortDescription,
  images,
  video,
  description,
  dateStart,
  datePublish,
  links,
  page,
}: ProjectItem) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslate();

  const id = useMemo(() => getHash(title), [title]);
  return (
    <>
      <Row className='project-item'>
        <Col>
          <Row>
            <Col className='title'>
              <h1>{title}</h1>
            </Col>
          </Row>
          <Row className='content'>
            <Col className='image-wrapper'>
              <img src={mainImage} alt={title} />
            </Col>
            <Col className='description-block-wrapper'>
              <Row className='description-wrapper'>
                <Col>{shortDescription}</Col>
              </Row>
              <Row className='buttons'>
                <button onClick={() => setModalVisible(true)}>{t('pages.projects.openContentButton')}</button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <ProjectModal
        id={id.toString()}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        title={title}
        groupId={0}
        images={images}
        mainImage={mainImage}
        description={description}
        dateStart={dateStart}
        datePublish={datePublish}
        links={links}
        page={page}
        video={video}
        shortDescription={shortDescription}
      />
    </>
  );
};
