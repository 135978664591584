import { ChangeEvent, ReactNode, useRef } from 'react';
import { makeId } from '../utils/helpers';

export interface InputProps {
  placeholder?: string;
  value?: string | number;
  onChange: (value: string, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  title: ReactNode;
  id?: string;
  type: string;
  disabled?: boolean;
  beforeTitle?: ReactNode;
}

export const Input = ({
  beforeTitle,
  id = makeId(),
  type,
  value,
  onChange,
  placeholder,
  title,
  disabled = false,
}: InputProps) => {
  const inputProps = {
    id,
    value,
    placeholder,
    disabled,
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChange(e.target.value, e),
  };

  const uploadRef = useRef<HTMLInputElement | null>(null);

  if (type === 'checkbox' || type === 'radio') {
    return (
      <>
        {beforeTitle}
        <input {...inputProps} type={type} />
        <label htmlFor={id}>{title}</label>
      </>
    );
  }

  if (type === 'file') {
    return (
      <>
        {beforeTitle}
        <button disabled={disabled} onClick={() => uploadRef?.current?.click()}>
          {title}
        </button>
        <input id={id} ref={uploadRef} style={{ display: 'none' }} onChange={(e) => onChange('', e)} type={type} />
      </>
    );
  }

  return (
    <>
      <label htmlFor={id}>{title}</label>
      {beforeTitle}
      {type !== 'textarea' ? <input {...inputProps} type={type} /> : <textarea {...inputProps} />}
    </>
  );
};
