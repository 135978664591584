import axios from 'axios';
import { ServerActions, ServerApiPath, ServerDateFormat, UseMocks } from '../utils/consts';
import { useMutation, UseMutationResult } from 'react-query';
import { MultiLangNewsItem, MultiLangNewsItemRaw, NewsItem, NewsItemRaw } from '../utils/interfaces';
import moment from 'moment';
import { Locale } from '../hooks/useTranslate';
import { getToken } from '../utils/helpers';

const fetch = async (data: MultiLangNewsItem): Promise<void> => {
  if (UseMocks) {
    return;
  }

  const rawData: MultiLangNewsItemRaw = {} as MultiLangNewsItemRaw;

  for (const lang in data) {
    const item = data[lang as Locale] as NewsItem;

    rawData[lang as Locale] = {
      id: item.id,
      groupId: item.groupId,
      title: item.title,
      text: item.text,
      date: moment(item.date).format(ServerDateFormat),
    } as NewsItemRaw;
  }

  const token = getToken();
  await axios.post(`${ServerApiPath}?action=${ServerActions.saveNews}&token=${token}`, { data: rawData });
  return;
};

export const useSaveNews = (): UseMutationResult<void, unknown, MultiLangNewsItem, unknown> => {
  return useMutation((data: MultiLangNewsItem) => fetch(data));
};
