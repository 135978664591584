import { useState, useEffect } from 'react';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const Breakpoints: Record<number, Breakpoint> = {
  0: 'xs',
  576: 'sm',
  768: 'md',
  992: 'lg',
  1200: 'xl',
  1400: 'xxl',
};
const Sizes = Object.keys(Breakpoints).map((value) => parseInt(value, 10));

const useBreakpoint = () => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('orientationchange', handleResize);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('orientationchange', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let breakpoint = Breakpoints[Sizes[0]];

  for (let i = 1; i < Sizes.length; i++) {
    breakpoint = Breakpoints[Sizes[i]] as Breakpoint;

    if (windowWidth < Sizes[i]) {
      break;
    }
  }
  let hBreakpoint = Breakpoints[Sizes[0]];
  for (let i = 1; i < Sizes.length; i++) {
    hBreakpoint = Breakpoints[Sizes[i]] as Breakpoint;

    if (windowHeight < Sizes[i]) {
      break;
    }
  }

  return { breakpoint, hBreakpoint };
};

export { useBreakpoint };
