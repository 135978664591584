import { Row } from './Row';
import { Col } from './Col';
import { Locale, useTranslate } from '../hooks/useTranslate';
import { useState } from 'react';
import { Input } from './Input';
import { MultiLangString, SubContentByLang } from '../utils/interfaces';
import { copy } from '../utils/helpers';

export interface MultiLangMultiInputProps {
  values?: MultiLangString;
  onAdd: (lang: Locale, value: string[]) => void;
  titles: string[];
  types: string[];
  disabled?: boolean;
  subContentByLang?: SubContentByLang;
  buttonTitle: string;
  mainTitle: string;
}

const getDefault = (localeList: Locale[]) => {
  const result = {} as MultiLangString;
  for (const lang of localeList) {
    result[lang] = [] as string[];
  }

  return result;
};

export const MultiLangMultiInput = ({
  subContentByLang,
  values,
  onAdd,
  titles,
  types,
  mainTitle,
  disabled = false,
  buttonTitle,
}: MultiLangMultiInputProps) => {
  const { localeList } = useTranslate();
  const [data, setData] = useState<MultiLangString>(values || getDefault(localeList));
  const [activeLocale, setActiveLocale] = useState<Locale>(localeList[0]);

  const AddElement = () => {
    onAdd(activeLocale, data[activeLocale as Locale] as string[]);
    const newData = copy(data);
    newData[activeLocale] = [];
    setData(newData);
  };

  const UpdateValue = (newValue: string, index: number) => {
    const newData = copy(data);
    (newData[activeLocale] as string[])[index] = newValue;
    setData(newData);
  };

  return (
    <Row className='multi-lang-input'>
      <Col>
        <Row className='main-title-wrapper'>
          <Col>{mainTitle}</Col>
        </Row>
        {subContentByLang &&
          Object.keys(subContentByLang).map((lang) => (
            <Row useSpan={true} key={lang} className='sub-content-item'>
              <Col>{subContentByLang[lang as Locale]}</Col>
            </Row>
          ))}
        {titles.map((title, index) => {
          let value = '';
          if ((data[activeLocale] as string[]).length > index) {
            value = (data[activeLocale] as string[])[index];
          }

          return (
            <Row key={title}>
              <Col>
                <Input
                  onChange={(value) => UpdateValue(value, index)}
                  title={title}
                  disabled={disabled}
                  type={types[index]}
                  value={value}
                  beforeTitle={
                    <Row className='locale-list-wrapper'>
                      {localeList.map((item) => (
                        <Col key={item}>
                          <button
                            className={`${item === activeLocale ? 'active' : ''}`}
                            onClick={() => setActiveLocale(item)}
                          >
                            {item}
                          </button>
                        </Col>
                      ))}
                    </Row>
                  }
                />
              </Col>
            </Row>
          );
        })}

        <Row>
          <Col>
            <button onClick={AddElement}>{buttonTitle}</button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
