import { Modal } from '../../components';
import { getHash } from '../../utils/helpers';

interface ImageModalProps {
  image: string;
  modalVisible: boolean;
  hideModal: () => void;
}

export const ImageModal = ({ image, modalVisible = false, hideModal }: ImageModalProps) => {
  const id = getHash(image);
  return (
    <Modal
      className='image-wrapper-modal'
      id={id.toString()}
      visible={modalVisible}
      onClose={hideModal}
      options={{ level: 2 }}
    >
      <img alt={image} src={image} />
    </Modal>
  );
};
