import { ContentNewsProps } from '../../utils/interfaces';
import { Col, Icon, Pagination, Row } from '../../components';
import { Item } from './item';
import { useBreakpoint, Breakpoint } from '../../hooks/useBreakpoint';
import { PublicShortDateFormat } from '../../utils/consts';

const CountItemsPerBreakPoint: { [key in Breakpoint]: number } = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 5,
  xl: 5,
  xxl: 5,
} as const;

export const Mobile = ({ data, activeItem, setActiveItem }: ContentNewsProps) => {
  const { breakpoint } = useBreakpoint();

  return (
    <>
      <Row>
        <Col className='list mobile'>
          <Pagination
            items={data.map((item, index) => (
              <Item
                {...item}
                title={item.title}
                key={item.date.toString()}
                format={PublicShortDateFormat}
                active={index === activeItem}
                onClick={() => setActiveItem(index)}
              />
            ))}
            prevText={<Icon icon='icon-left' />}
            nextText={<Icon icon='icon-right' />}
            maxToShow={CountItemsPerBreakPoint[breakpoint]}
            active={activeItem}
            useOffset={true}
          />
        </Col>
      </Row>
      <Row className='content'>
        <Col className='active-element'>
          <Item {...data[activeItem]} opened={true} />
        </Col>
      </Row>
    </>
  );
};
