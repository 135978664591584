import { FormEvent, ReactNode, useState } from 'react'

interface SubmitInputProps {
  onSubmit: (value: string) => void
  placeholder?: string
  type: string
  submitTitle: ReactNode
  onValidate?: (value: string) => void
}

export const SubmitInput = ({ onValidate, submitTitle, type, placeholder, onSubmit }: SubmitInputProps) => {
  const [email, setEmail] = useState<string>('')

  const onSubmitClick = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    onSubmit(email)
  }

  return (
    <form onSubmit={onSubmitClick} className={'submit-button'}>
      <input
        type={type}
        placeholder={placeholder}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value)
          onValidate?.(e.target.value)
        }}
      />
      <button type='submit'>{submitTitle}</button>
    </form>
  )
}
